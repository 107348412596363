import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="89"
      height="89"
      fill="none"
      viewBox="0 0 89 89"
    >
      <g clipPath="url(#clip0_434_332)">
        <path
          fill="#1F1F1F"
          d="M86.674 44.33H84.95v-3.473a6.95 6.95 0 00-5.176-6.725V16.55c0-3.83-3.097-6.946-6.902-6.946H15.929c-3.805 0-6.902 3.116-6.902 6.946v17.582a6.95 6.95 0 00-5.176 6.725v3.473H2.126A1.73 1.73 0 00.4 46.066V77.32a1.73 1.73 0 001.726 1.736h6.901c.654 0 1.252-.371 1.544-.96l2.974-5.985h61.71l2.974 5.986c.293.588.89.96 1.544.96h6.901c.953 0 1.726-.778 1.726-1.737V46.066a1.73 1.73 0 00-1.726-1.736zM3.852 68.638v-3.473h81.098v3.473H3.851zm48.314-37.33a2.6 2.6 0 012.588-2.605h12.078a2.6 2.6 0 012.589 2.605 2.6 2.6 0 01-2.589 2.604H54.753a2.6 2.6 0 01-2.588-2.604zM66.83 25.23H54.753c-3.33 0-6.04 2.726-6.04 6.077 0 .931.21 1.815.585 2.604h-9.796c.374-.79.584-1.673.584-2.604 0-3.35-2.709-6.077-6.039-6.077H21.97c-3.33 0-6.04 2.726-6.04 6.077 0 .931.21 1.815.584 2.604h-4.035V21.758h63.844v12.154h-4.035c.374-.79.584-1.673.584-2.604 0-3.35-2.71-6.077-6.04-6.077zm-47.45 6.077a2.6 2.6 0 012.588-2.605h12.078a2.6 2.6 0 012.588 2.605 2.6 2.6 0 01-2.588 2.604H21.97a2.6 2.6 0 01-2.589-2.604zm-3.452-18.231h56.942c1.903 0 3.45 1.558 3.45 3.473v1.736H12.479V16.55c0-1.915 1.549-3.473 3.451-3.473zm-8.627 27.78c0-1.914 1.548-3.472 3.451-3.472h67.294c1.903 0 3.451 1.558 3.451 3.472v3.473H7.302v-3.473zm77.647 6.946v13.89H3.851v-13.89h81.098zM7.961 75.583h-4.11V72.11h5.836L7.96 75.583zm72.878 0l-1.725-3.473h5.835v3.473h-4.11z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_434_332">
          <path
            fill="#fff"
            d="M0 0H88V88.55H0z"
            transform="translate(.4 .055)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
