import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="117"
      fill="none"
      viewBox="0 0 116 117"
    >
      <g fill="#1F1F1F" clipPath="url(#clip0_434_198)">
        <path d="M111.874 74.801H108.8v-8.16c.048-6.438-5.092-11.7-11.49-11.764H29.6v-25.96h9c.555 0 1.078-.258 1.42-.697.34-.44.46-1.014.326-1.555L35.54 7.345a1.801 1.801 0 00-1.74-1.371h-12c-.826-.001-1.546.564-1.748 1.37l-4.798 19.321a1.821 1.821 0 00.327 1.555c.341.44.864.697 1.419.697h9v25.96h-8.311c-6.398.064-11.537 5.326-11.489 11.765v8.16H4.926c-2.278.001-4.124 1.86-4.126 4.15v23.018c0 1 .806 1.811 1.8 1.811h3.6v2.828c.003 2.438 1.967 4.415 4.39 4.417h95.62c2.423-.002 4.387-1.979 4.39-4.417v-2.828h3.6c.994 0 1.8-.811 1.8-1.811V78.953c-.002-2.292-1.848-4.15-4.126-4.152zM19.305 25.294l3.9-15.698h9.19l3.9 15.698h-16.99zM9.738 66.642c-.032-4.456 3.524-8.097 7.951-8.142h79.622c4.427.045 7.983 3.686 7.951 8.142v8.16h-1.336c-2.278.001-4.124 1.86-4.126 4.15v4.906H17v-4.905c-.002-2.292-1.848-4.15-4.126-4.152H9.738v-8.16zM99.8 87.48v12.679H17V87.48h82.8zM4.4 100.159V78.953c0-.292.236-.53.526-.53h7.948c.29 0 .526.238.526.53v21.206h-9zm102.6 6.45a.795.795 0 01-.79.795H10.59a.795.795 0 01-.79-.795v-2.828H107v2.828zm5.4-6.45h-9V78.953c0-.292.236-.53.526-.53h7.948c.29 0 .526.238.526.53v21.206z"></path>
        <path d="M26 62.123h3.6v3.622H26v-3.623zM26 72.99h3.6v3.623H26V72.99zM87.2 62.123h3.6v3.622h-3.6v-3.623zM87.2 72.99h3.6v3.623h-3.6V72.99zM56.6 62.123h3.6v3.622h-3.6v-3.623zM56.6 72.99h3.6v3.623h-3.6V72.99zM47.6 49.444H107c.994 0 1.8-.811 1.8-1.812V9.596c0-1-.806-1.811-1.8-1.811H47.6c-.994 0-1.8.811-1.8 1.811v38.036c0 1 .806 1.812 1.8 1.812zm1.8-38.037h55.8v34.414H49.4V11.408z"></path>
        <path d="M101.6 13.219H53c-.994 0-1.8.81-1.8 1.811v27.169c0 1 .806 1.811 1.8 1.811h48.6c.994 0 1.8-.811 1.8-1.811V15.03c0-1-.806-1.811-1.8-1.811zm-1.8 27.169h-45V16.84h45v23.547z"></path>
      </g>
      <defs>
        <clipPath id="clip0_434_198">
          <path
            fill="#fff"
            d="M0 0H115.2V115.92H0z"
            transform="translate(.8 .54)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
