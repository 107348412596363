import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      fill="none"
      viewBox="0 0 46 46"
    >
      <g fill="#039C94" clipPath="url(#clip0_98_65)">
        <path d="M23 0C10.298 0 0 10.298 0 23s10.298 23 23 23 23-10.298 23-23C45.997 10.298 35.702 0 23 0zm14.391 28.97c-.07 1.54-.314 2.592-.673 3.516a7.09 7.09 0 01-1.669 2.563 7.115 7.115 0 01-2.563 1.67c-.921.358-1.976.602-3.517.672-1.544.07-2.036.088-5.969.088-3.933 0-4.425-.018-5.97-.088-1.54-.07-2.592-.314-3.516-.673a7.09 7.09 0 01-2.563-1.669 7.115 7.115 0 01-1.67-2.563c-.358-.921-.602-1.976-.672-3.517-.07-1.544-.088-2.036-.088-5.969 0-3.933.018-4.425.088-5.97.07-1.54.314-2.592.673-3.516a7.09 7.09 0 011.669-2.563 7.115 7.115 0 012.563-1.67c.921-.358 1.976-.602 3.517-.672 1.544-.07 2.036-.088 5.969-.088 3.933 0 4.425.018 5.97.088 1.54.07 2.592.314 3.516.673a7.09 7.09 0 012.563 1.669 7.115 7.115 0 011.67 2.563c.358.921.602 1.976.672 3.517.07 1.544.088 2.036.088 5.969 0 3.933-.015 4.425-.088 5.97z"></path>
        <path d="M34.286 14.458a4.487 4.487 0 00-1.084-1.666 4.486 4.486 0 00-1.666-1.084c-.51-.198-1.276-.434-2.69-.498-1.526-.07-1.983-.084-5.852-.084-3.866 0-4.323.014-5.852.084-1.413.064-2.18.3-2.69.498a4.485 4.485 0 00-1.666 1.084c-.507.507-.821.99-1.083 1.666-.199.51-.435 1.276-.499 2.69-.07 1.526-.084 1.983-.084 5.852 0 3.866.014 4.323.084 5.853.064 1.413.3 2.179.498 2.689.263.675.577 1.159 1.084 1.666.507.507.99.822 1.667 1.084.51.198 1.276.434 2.689.498 1.526.07 1.983.084 5.852.084 3.866 0 4.326-.014 5.853-.084 1.413-.064 2.179-.3 2.689-.498a4.487 4.487 0 001.666-1.084c.507-.507.822-.99 1.084-1.666.198-.51.434-1.276.498-2.69.07-1.526.084-1.983.084-5.852 0-3.866-.014-4.323-.084-5.853-.061-1.413-.297-2.179-.498-2.689zM23 30.434a7.437 7.437 0 110-14.874 7.437 7.437 0 010 14.874zm7.729-13.427a1.737 1.737 0 11.001-3.473 1.737 1.737 0 01-.001 3.473z"></path>
        <path d="M23 27.827a4.827 4.827 0 100-9.654 4.827 4.827 0 000 9.654z"></path>
      </g>
      <defs>
        <clipPath id="clip0_98_65">
          <path fill="#fff" d="M0 0H46V46H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
