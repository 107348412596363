import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="113"
      fill="none"
      viewBox="0 0 112 113"
    >
      <g fill="#1F1F1F" clipPath="url(#clip0_434_108)">
        <path d="M83.857 49.456c-.961 0-1.741.79-1.741 1.761v10.566c0 .972.78 1.76 1.741 1.76.961 0 1.741-.788 1.741-1.76V51.217c0-.972-.78-1.76-1.74-1.76zM64.705 49.456c-.96 0-1.74.79-1.74 1.761v10.566c0 .972.78 1.76 1.74 1.76.961 0 1.741-.788 1.741-1.76V51.217c0-.972-.78-1.76-1.74-1.76z"></path>
        <path d="M99.527.15H12.473c-.96 0-1.74.789-1.74 1.761v109.178c0 .972.78 1.761 1.74 1.761h87.054c.961 0 1.741-.789 1.741-1.761V1.911c0-.972-.78-1.761-1.741-1.761zM14.214 77.631h34.822v10.566H14.214V77.63zm34.822-3.522H14.214V63.544h34.822v10.565zm0-14.087H14.214V10.716h34.822v49.306zM14.214 91.719h34.822v10.565H14.214V91.719zm38.304-81.003H73.41v91.568H52.518V10.716zm24.375 0h20.893v91.568H76.893V10.716zm20.893-3.522H76.893V3.672h20.893v3.522zM73.41 3.672v3.522H52.518V3.672H73.41zm-59.197 0h34.822v3.522H14.214V3.672zm0 102.134h34.822v3.522H14.214v-3.522zm38.304 0H73.41v3.522H52.518v-3.522zm45.268 3.522H76.893v-3.522h20.893v3.522z"></path>
        <path d="M28.143 70.588h6.964c.961 0 1.741-.79 1.741-1.761 0-.972-.78-1.761-1.74-1.761h-6.965c-.961 0-1.741.789-1.741 1.76 0 .973.78 1.762 1.74 1.762zM35.107 81.153h-6.964c-.961 0-1.741.789-1.741 1.761 0 .972.78 1.761 1.74 1.761h6.965c.961 0 1.741-.789 1.741-1.76 0-.973-.78-1.762-1.74-1.762zM35.107 95.24h-6.964c-.961 0-1.741.79-1.741 1.762s.78 1.76 1.74 1.76h6.965c.961 0 1.741-.788 1.741-1.76s-.78-1.761-1.74-1.761zM43.813 42.413c.96 0 1.74-.79 1.74-1.761V30.086c0-.972-.78-1.761-1.74-1.761-.962 0-1.742.789-1.742 1.76v10.567c0 .972.78 1.76 1.742 1.76z"></path>
      </g>
      <defs>
        <clipPath id="clip0_434_108">
          <path
            fill="#fff"
            d="M0 0H111.429V112.7H0z"
            transform="translate(.286 .15)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
